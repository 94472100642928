<template>
<!--   <div>
    <div class="div_center">
      <div style="width: 1200px;margin: 0 auto;text-align: center" class="qytj">
        <span>科技项目评测系统</span>
        <span>企业手中的科技项目申报指南</span>
        <span style="font-size: 31px">快速获取科学、精确的评测报告</span>
        <span>一键诊断高企通过率！轻松申报政府百万资金项目！</span>
        <span @click="goExam()" style="cursor: pointer">立即申报</span>
      </div>
    </div>
    <div class="center" style="height: 940px;">
      <div style="
    margin-top: 80px;
    margin-bottom: 50px;
  font-size: 28px;
    color: rgb(51, 51, 51);
">便携流程
      </div>
      <div class="bxlc">

        <div style="text-align: center;padding-top: 40px">
          <img :src="jbxx" alt=""><br>
          <span style="font-size: 16px;color: black;font-weight: bold;line-height: 80px">填写基本信息</span>
        </div>
        <span>>>></span>

        <div style="text-align: center;padding-top: 40px">

          <img :src="cqzk" alt=""><br>
          <span style="font-size: 16px;color: black;font-weight: bold;line-height: 80px">填写企业知识产权状况</span>
        </div>
        <span>>>></span>
        <div style="text-align: center;padding-top: 40px">

          <img :src="glzk" alt=""><br>
          <span style="font-size: 16px;color: black;font-weight: bold;line-height: 80px">确认企业财务及研发管理状况</span>
        </div>
        <span>>>></span>
        <div style="text-align: center;padding-top: 40px">

          <img :src="csbg" alt=""><br>
          <span style="font-size: 16px;color: black;font-weight: bold;line-height: 80px">提交生成测评报告</span>
        </div>
      </div>
      <div class="center" style="height: 600px;display: flex">
        <div style="height: 100%;width: 600px;display: flex;justify-content: space-between;align-items: center">
          <img :src="computer" alt="" style="">
          <img :src="arrow2"></img>
        </div>
        <div style="height: 100%;width: 600px;padding-top: 130px;padding-left: 40px">
          <div style="font-size: 28px;font-weight: bold;margin-bottom: 10px">产品简介</div>
          <div style="font-size: 14px;line-height: 30px">企业科技项目测评系统通过便捷的输入与反馈模式为企业提供强关联性的科技项目的申报指引、条件符合度、评分估算、解决方案对接等功能。<br>
            系统基于海量的政策梳理与格式化建模企业数据采集、加工、画像，利用大数据技术进行双向匹配并自动产出评测报告，成为企业申报项目的高效引导辅助工具。
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 400px; background-color: rgb(248, 250, 252); ">
      <div class="center" style="height: 400px">
        <Row>
          <Col span="12">
            <div style="height: 400px;width: 100%;padding: 100px 0" class="gqcp">
              <span style="font-size: 28px;color: black;font-weight: bold">高企测评</span>
              <span style="font-size: 20px;color: black">作为现阶段主打特色服务国家高新技术企业认定测评功能已全新上线！</span>
              <span
                style="font-size: 14px">国家高新技术企业是中国高科技企业最高荣誉之一，获评“国家高新技术企业”的公司不仅能收获国家级资质认证硬招牌取得丰厚的资金补贴还可畅享税收优惠等政策福利</span>
            </div>
          </Col>
          <Col span="12">
            <div style="display: flex;align-items: center;justify-content: center;padding-top: 60px">
              <img :src="lazy" alt="">
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div style="width: 100%;height: 630px;position: relative" class="anli">
      <div class="center" style="height: 630px;display: flex">
        <div style="width: 350px;height: 100%;padding-right: 40px">
          <span style="display: block;margin-top: 150px;margin-bottom: 55px;font-size: 28px">成功案例</span>
          <span style="display: inline-block;font-size: 14px;line-height: 25px">江苏省某制造型企业，成立时间为2014年12月，有自主知识产权，该公司2016年可加计的研发费用100万，2017年可加计的研发费用为300万元，2017年应纳税所得额为400万。其可获相关的税收优惠和科技项目支持为：</span>
        </div>
        <div style="width: 850px;height: 100%;background-color: #0072dc;padding-left: 70px;padding-top: 68px">
          <div class="sszc">税收政策</div>
          <div style="font-size: 14px;color: white;line-height: 30px">>>&nbsp;按照正常25%所得税税率&nbsp;&nbsp; &nbsp;
            所得税是：400*25%=100万
          </div>
          <div style="font-size: 14px;color: white;line-height: 30px">>>&nbsp;既是高新技术企业，按照高新技术企业研发费用175%加计扣除 &nbsp;
            &nbsp; 所得税是：（400-300*75%）*15%=26.25
            <br>万
          </div>
          <div style="font-size: 14px;color: white;line-height: 30px;margin-top: 40px;margin-bottom: 50px">
            通过认定后，享受高新技术企业优惠税率当年可节企业所得税40万；研发费用进行加计扣除后，还可节企业所得税26.25万，累计节企业所得税66.25万
          </div>
          <div class="sszc" style="width: 150px">科技项目支持</div>
          <div style="font-size: 14px;color: white;line-height: 30px">>>&nbsp;高新技术企业奖励：主流城市50-200万不等；</div>
          <div style="font-size: 14px;color: white;line-height: 30px">>>&nbsp;研发奖补项目：按照研发费用加计扣除增长10%予以补助；</div>
          <div style="font-size: 14px;color: white;line-height: 30px">>>&nbsp;科技类项目优先立项，另有不同数额直接现金奖励。</div>
        </div>
      </div>
      <div style="position: absolute;background-color:#0072dc;top: 0;right: 0;height: 100% "
           :style="{'width':border_width}"></div>
    </div>
    <div style="height: 310px;text-align: center;width: 100%;">
      <div class="center" style="height: 310px;padding-top: 100px">
        <div class="bottom_div" style="color: black;font-weight: bold">【更多测评】</div>
        <div class="bottom_div">农业高新企业测评、小巨人企业测评、……</div>
        <div class="bottom_div">即将开放！</div>
      </div>

    </div>
  </div> -->


  <div class="content">
    <div class="head">
      <div class="head_content">
        <div class="head_tit1">一键诊断</div>
        <div class="head_tit1">企业手中的科技项目申报指南</div>
        <div class="head_tit2">评测报告更快速 更精准 更详细，助力企业项目申报</div>
        <div class="check_btn mt30" @click="goEnterprise">  
          <div>进一步了解</div>
          <img src="@/assets/image/index/arrow_r.png" class="arrow_r">
        </div>
        <div class="head_tel">或致电：0519-85581916</div>
      </div>
    </div>
    <div class="custody">
      <div class="custody_tit">企业体检介绍</div>
      <div class="custody_fs1">为您的企业提供专业数据评估</div>
      <div class="custody_desc_box">
        <img src="@/assets/image/index/qxtj_desc.png" class="custody_img">
        <div class="custody_right">
          <div class="custody_desc">
            <div class="custody_desc_up">
              <div class="border_l"></div>
              <img src="@/assets/image/index/icon_tj.png" class="icon_tj">
              <div class="down_fs">
                <div class="up_fs1">产品介绍</div>
                <div class="up_fs2">企业科技项目测评系统通过便捷的输入与反馈模式为企业提供强关联性的科技项目的申报指引、条件符合度、评分估算、解决方案对接等功能。</div>
                <div class="up_fs2">系统基于海量的政策梳理与格式化建模企业数据采集、加工、画像，利用大数据技术进行双向匹配并自动产出评测报告，成为企业申报项目的高效引导辅助工具。</div>
              </div>
            </div>
            <div class="custody_desc_down">
              <div class="down_fs">
                <div>专业服务团队</div>
                <div>无形资产交易、布局企业生态全周期的一站式服务平台</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step">
      <div class="custody_tit">企业体检流程</div>
      <div class="custody_fs1">告别繁琐操作，大幅提高专利业务办理效率</div>
      <div class="step_box">
        <img src="@/assets/image/index/step_img.png"/>
      </div>
    </div>
    <div class="test">
      <div class="custody_tit">高企测评</div>
      <div class="custody_fs1">作为现阶段主打特色服务国家高新技术企业认定测评功能已全新上线</div>
      <div class="custody_desc_box">
        <img src="@/assets/image/index/zjfc_img.png" class="zjfc_img">
        <div class="test_right">
          <div class="test_fs1">高企认定的价值</div>
          <div class="test_fs2">国家高新技术企业是中国高科技企业最高荣誉之一，获评“国家高新技术企业”的公司不仅能收获国家级资质认证硬招牌取得丰厚的资金补贴还可畅享税收优惠等政策福利</div>
        </div>
      </div>
    </div>
    <div class="case">
      <div class="custody_tit">成功案例</div>
      <div class="custody_fs1">企业体检，提供专业数据评估，为您的企业保驾护行</div>
      <div class="case_box">
        <div class="case_l">
          <div class="case_fs1">案例介绍</div>
          <div class="case_fs2 ti2">
            江苏省某制造型企业，成立时间为2014年12月，
            有自主知识产权，该公司2016年可加计的研发费用100万，
            2017年可加计的研发费用为300万元，2017年应纳税所得额为400万。
            其可获相关的税收优惠和科技项目支持为：
          </div>
          <img class="icon_case" src="@/assets/image/index/icon_case1.png"/>
          <div class="case_fs2">
            <p>>> 认证前：按照正常25%所得税税率 所得税是：400*25%=100万</p>
            <p>>> 认证后：按照高新技术企业研发费用175%加计扣除 所得税是：</p>
            <p>（400-300*75%）*15%=26.25万 </p>
            <p class="mt8">通过认定后，享受高新技术企业优惠税率当年可节企业所得税40万；研发费用进行加计扣除后，还可节企业所得税26.25万，累计节企业所得税66.25万</p>
          </div>
          <img class="icon_case" src="@/assets/image/index/icon_case2.png"/>
          <div class="case_fs2">
            <p>>> 高新技术企业奖励：主流城市50-200万不等；</p>
            <p>>> 研发奖补项目：按照研发费用加计扣除增长10%予以补助；</p>
            <p>>> 科技类项目优先立项，另有不同数额直接现金奖励。</p>
          </div>
        </div>
        <img src="@/assets/image/index/tj_case_img.png">
      </div>
    </div>
  </div>

</template>

<script>
import computer from '@/assets/image/index/computer.png'
import arrow2 from '@/assets/image/index/arrow2.png'
import lazy from '@/assets/image/index/lazy.png'
import cqzk from '@/assets/image/index/cqzk.png'
import csbg from '@/assets/image/index/csbg.png'
import glzk from '@/assets/image/index/glzk.png'
import jbxx from '@/assets/image/index/jbxx.png'
import {mapGetters} from 'vuex'


import { checkLogin } from "../../plugins/api/loginApi";
export default {
  computed: {
    ...mapGetters({
      token: 'userStore/token',
      userName: 'userStore/userName',
      userId: 'userStore/userId',
      enterpriseState: 'userStore/enterpriseState'
    }),
    border_width() {

      return (document.body.clientWidth - 1200) / 2 + 'px'
    }
  },
  name: 'ExaminationIndex',
  data() {
    return {
      cqzk: cqzk,
      csbg: csbg,
      glzk: glzk,
      jbxx: jbxx,
      computer: computer,
      arrow2: arrow2,
      lazy: lazy
    }
  },
  methods:{
      goEnterprise() {
        if (!checkLogin(this.$router)) {
          this.logOut();
          return;
        }
        this.$router.push({name: "enterprise", params:{pgId: "enterprise-physical-examination"}});
      },
    goExam(){
      if(this.enterpriseState !== 'CERTIFIED'){
        this.$router.push({
          path:'/enterprise/EnterpriseCertification'
        })
      }else{
        this.$router.push({
          path:'/enterprise/enterprise-physical-examination'
        })
      }
    }
  }
}

</script>

<style scoped lang="scss">
.bottom_div {
  height: 30px;
  line-height: 30px;
}

.sszc {
  width: 110px;
  border-radius: 20px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
  background-image: linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  background-image: -webkit-linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  background-image: -ms-linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  margin-bottom: 20px;
}

.anli {
  background-position: center;
  background-image: url("~@/assets/image/index/anli.png");
}

.gqcp span {
  display: block;
  width: 480px;
  margin-bottom: 20px;
}

.qytj span {
  display: block;
}

.bxlc {
  display: flex;
}

.bxlc > div {
  height: 230px;
  width: 240px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.bxlc > span {
  padding: 0 25px;
  width: 80px;
  height: 230px;
  line-height: 230px;
  color: #406DDD;
  font-weight: bolder;
}

.qytj span:first-child {
  color: #79EB94;
  margin-left: 200px;
  margin-top: 50px;
  border: 1px solid #79EB94;
  font-weight: bold;
  width: 135px;
  padding: 5px 5px;
  border-radius: 4px;
}

.qytj span:last-child {
  background-image: -moz-linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  background-image: -webkit-linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  background-image: linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  border-radius: 20px;
  font-size: 30px;
  line-height: 53px;
  width: 186px;
  height: 53px;
  color: rgb(41, 74, 212);
  margin: 0 auto;
  margin-top: 10px;

}

.qytj span:nth-child(4) {
  margin-top: 20px;
  font-size: 38px;
  color: white;
  font-weight: bold;
}

.qytj span:nth-child(3) {
  margin-top: 20px;
  font-size: 35px;
  font-weight: bold;
  color: rgb(41, 74, 212);
}

.qytj span:nth-child(2) {
  font-size: 60px;
  color: white;
  font-weight: bold;
}

.center {
  width: 1200px;
  height: 510px;

  margin: 0 auto;
}

.div_center {
  height: 510px;
  width: 100%;
  padding-top: 50px;
  justify-content: center;
  margin: 0 auto;
  background-image: url("~@/assets/image/index/examIndex.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


  /**************************new***************************/
  .mt12{
    margin-top: 24px;
  }
  .mt30{
    margin-top: 60px;
  }
  .content{
    width: 100%;
    min-width: 1440px;
  }
  .head{
    width: 100%;
    height: 500px;
    background-image: url("~@/assets/image/index/examIndex_new.png");
    /*background-size: 1920px 500px;*/
  }
  .head_content{
    padding-top: 155px;
    margin-left: 398px;
  }

  .head_tit1{
    /*height: 100px;*/
    font-size: 36px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 50px;
  }
  .head_tit2{
    margin-top: 12px;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .head_tel{
    margin-top: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .check_btn{
    width: 136px;
    height: 46px;
    background: #1776FF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
    /*line-height: 21px;*/
  }
  .arrow_r{
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }

  .point{
    width: 100%;
    height: 115px;
    background: #F6F9FD;
  }
  .point_box{
    width: 100%;
    display: flex;
    justify-content: center;

  }
  .point_item{
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 115px;
  }
  .point_icon{
    width: 40px;
    height: 40px;
  }
  .point_desc{
    margin-left: 24px;
  }

  .point_fs1{
    font-size: 16px;
    font-weight: 600;
    color: #484F64;
  }

  .point_fs2{
    font-size: 12px;
    font-weight: 400;
    color: #848CA1;
  }

  .custody{
    width: 100%;
    /*height: 632px;*/
    padding-top: 50px;
    padding-bottom: 100px;
    /*margin-top: 50px;*/
    /*background: #FFFFFF;*/
  }

  .custody_tit{
    text-align: center;
    height: 32px;
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    line-height: 32px;
  }
  .custody_fs1{
    text-align: center;
    margin-top: 24px;
    height: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }

  .custody_desc_box{
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .custody_img{
    width: 360px;
    height: 360px;
  }
  .custody_right{
    display: flex;
    align-items: center;
  }
  .custody_desc{
    margin-left: 60px;
    width: 760px;
  }
  .custody_desc_up{
    position: relative;
    width: 760px;
    height: 208px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }
  .up_fs1{
    font-size: 18px;
    font-weight: 600;
    color: #484F64;
    line-height: 30px;
  }
  .up_fs2{
    font-size: 16px;
    font-weight: 400;
    color: #484F64;
    line-height: 26px;
  }
  .border_l{
    width: 2px;
    height: 208px;
    background: #1890FF;
    position: absolute;
    left: 0;
  }
  .icon_tj{
    position: absolute;
    right: 30px;
    top: -6px;
  }
  .custody_desc_down{
    width: 760px;
    height: 110px;
    background: #F6F9FD;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    align-items: center;
  }

  .down_fs{
    width: 634px;
    margin-left: 35px;
    font-size: 16px;
    font-weight: 400;
    color: #484F64;
    line-height: 26px;
  }

  .step{
    width: 100%;
    height: 355px;
    background: #F6F9FD;
    border-radius: 4px;
    padding-top: 50px;
  }
  .step_box{
    text-align: center;
    margin-top: 44px;
  }

  .test{
    width: 1920px;
    height: 592px;
    background: #FFFFFF;
    padding-top: 50px;
    /*padding: 0px 360px;*/
  }

  .zjfc_img{
    width: 710px;
    height: 370px;
  }
  .test_right{
    margin-left: 64px;
    width: 427px;
  }
  .test_fs1{
    margin-top: 86px;
    font-size: 24px;
    font-weight: 600;
    color: #333333;
  }
  .test_fs2{
    margin-top: 34px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }

  .case{
    height: 712px;
    background: #F6F9FD;
    padding-top: 50px;
  }
  .case_box{
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  .case_l{
    width: 570px;
    margin-right: 30px;
  }
  .case_fs1{
    font-size: 24px;
    font-weight: 600;
    color: #333333;
  }
  .case_fs2{
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin-top: 16px;
  }
  .icon_case{
    display:block;
    margin-top: 22px;
  }
  .mt12{
    margin-top: 24px;
  }
  .mt8{
    margin-top: 16px;
  }
  .mt30{
    margin-top: 60px;
  }
  .ti2{
    text-indent: 2em
  }
</style>
